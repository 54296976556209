import React from "react";
import {
  Icon,
  CloseIcon,
  SidebarContainer,
  SidebarMenu,
  SidebarWrapper,
  SidebarLink,
} from "./SidebarStyles";

const Sidebar = ({ isOpen, toggle }) => {
  const Btn = "true";
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="about" onClick={toggle}>
            About
          </SidebarLink>
          <SidebarLink to="vessels" onClick={toggle}>
            Vessels
          </SidebarLink>
          <SidebarLink to="services" onClick={toggle}>
            Services
          </SidebarLink>
          <SidebarLink to="gallery" onClick={toggle}>
            Gallery
          </SidebarLink>
          <SidebarLink to="committee" onClick={toggle}>
            Committee
          </SidebarLink>
          <SidebarLink to="contactus" onClick={toggle} isbutton={Btn}>
            Contact Us
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
