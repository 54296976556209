import React, { useState } from "react";
import {
  Navbar,
  Sidebar,
  Hero,
  Aboutus,
  Vessels,
  Services,
  Gallery,
  Contactus,
  Footer,
  Rights,
  Highlights,
  Committee,
} from "../Components";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Hero />
      <Aboutus />
      <Highlights />
      <Vessels />
      <Services />
      <Gallery />
      <Committee />
      <Contactus />
      <Footer />
      <Rights />
    </div>
  );
};

export default Home;
