import React from "react";
import {
  HeroBg,
  HeroContainer,
  VideoBg,
  HeroP,
  HeroH1,
  HeroContent,
  HeroSubtilte,
  HeroButton,
} from "./HeroStyle";
import Video from "../../Images/Ship.m4v";
import { BsArrowRight } from "react-icons/bs";

const HeroSection = () => {
  return (
    <HeroContainer id="hero">
      <HeroBg>
        <VideoBg
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline
          src={Video}
          type="video/m4v"
        />
      </HeroBg>
      <HeroContent>
        <HeroH1>SITHIRA ENTERPRISES,</HeroH1>
        <HeroSubtilte>(Sub Agents at Trincomalee)</HeroSubtilte>
        <HeroP>Your Trusted Partner for Shipping Services</HeroP>
        <HeroP>
          Expertly Handling Services to Keep Your Business Moving Forward
        </HeroP>
        <HeroButton to="contactus" smooth={true} duration={500}>
          Get in Touch
          <BsArrowRight />
        </HeroButton>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
