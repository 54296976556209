import React from "react";
import { CommitteeData } from "../../Data";
import styled from "styled-components";
import { CommonHeading } from "../Hero/HeroStyle";

const CommiitteeSectionWrap = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 720px) {
    padding-left: 0;
  }
`;

const CommitteeSection = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const MemberCard = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  padding: 0.5rem;
  margin: 2rem;
  border-radius: 10px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
  h2 {
    font-size: 14pt;
  }
  h3 {
    font-size: 12pt;
  }
  @media screen and (max-width: 900px) {
    h2 {
      font-size: 12pt;
    }
    h3 {
      font-size: 11pt;
    }
    p {
      font-size: 10pt;
    }
  }
`;

const MemberPhoto = styled.img`
  width: 15rem;
  height: 18rem;
  border-radius: 10px;
  object-fit: cover;
  @media screen and (max-width: 900px) {
    width: 12rem;
    height: 14rem;
  }
`;

const Committee = () => {
  return (
    <CommiitteeSectionWrap id="committee">
      <CommonHeading>Management Committee Members...</CommonHeading>
      <CommitteeSection>
        {CommitteeData.map((item) => (
          <MemberCard>
            <MemberPhoto src={item.photo} alt="" />
            <h2>{item.name}</h2>
            <h3>{item.position}</h3>
            <p>{item.nmbr}</p>
          </MemberCard>
        ))}
      </CommitteeSection>
    </CommiitteeSectionWrap>
  );
};

export default Committee;
