import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const CommonHeading = styled.h1`
  padding: 5rem 1.5rem 0 0;
  font-size: 2.5rem;
  font-weight: 700;
  width: 74%;
  @media screen and (max-width: 768px) {
    padding: 5rem 1rem 0 1rem;
    font-size: 2.2rem;
    width: 90%;
    text-align: center;
  }
`;

export const HeroContainer = styled.div`
  background: #000;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 4rem;
  height: 100vh;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, transparent 1%);
    z-index: 2;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  bottom: 5rem;
  display: flex;
  flex-direction: column;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;
export const HeroSubtilte = styled.h3`
  margin-top: 4px;
  color: #fff;
  font-size: 38px;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }

  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`;

export const HeroP = styled.p`
  margin-top: 4px;
  color: #fff;
  font-size: 24px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const HeroButton = styled(LinkS)`
  border: 2px solid white;
  border-radius: 5px;
  background: none;
  margin-top: 15px;
  color: var(--white);
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14pt;
  font-weight: 500;
  padding: 0.7rem;
  gap: 1rem;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: black;
  }
  @media screen and (max-width: 520px) {
    font-size: 12pt;
  }
`;

export const HeroBtnIcon = styled.i`
  font-size: 14pt;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
`;
