import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import {
  NavLogo,
  NavMenu,
  NavItems,
  NavLinks,
  MobileIcon,
  NavImg,
  Nav,
  NavContainer,
} from "./NavbarStlyes";
import Logo from "../../Images/Logo.png";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const Btn = "true";

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <Nav scrollNav={scrollNav}>
      <NavContainer>
        <NavLogo to="hero" smooth={true} duration={500}>
          <NavImg src={Logo} />
        </NavLogo>
        <MobileIcon onClick={toggle}>
          <FaBars />
        </MobileIcon>
        <NavMenu>
          <div style={{ display: "flex", alignItems: "center" }}>
            <NavItems>
              <NavLinks to="about" smooth={true} duration={500}>
                About
              </NavLinks>
            </NavItems>
            <NavItems>
              <NavLinks to="vessels" smooth={true} duration={500}>
                Vessels
              </NavLinks>
            </NavItems>
            <NavItems>
              <NavLinks to="services" smooth={true} duration={500}>
                Services
              </NavLinks>
            </NavItems>
            <NavItems>
              <NavLinks to="gallery" smooth={true} duration={500}>
                Gallery
              </NavLinks>
            </NavItems>
            <NavItems>
              <NavLinks to="committee" smooth={true} duration={500}>
                Committee
              </NavLinks>
            </NavItems>
          </div>
          <NavItems>
            <NavLinks
              to="contactus"
              smooth={true}
              duration={500}
              isbutton={Btn}
            >
              Contact Us
            </NavLinks>
          </NavItems>
        </NavMenu>
      </NavContainer>
    </Nav>
  );
};

export default Navbar;
