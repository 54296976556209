import styled from "styled-components";

export const ContactusContainer = styled.section`
  min-height: 100vh;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: var(--white);
  gap: 5rem;
  @media screen and (max-width: 1000px) {
    gap: 0.5rem;
  }
`;

export const ContactusTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12pt;
  width: 30rem;
  height: 70vh;
  padding: 0 2rem;
  min-height: min-content;
  @media screen and (max-width: 1000px) {
    height: 60vh;
    width: 30rem;
    padding: 0;
  }
  @media screen and (max-width: 700px) {
    width: 90%;
    margin: 10px;
  }
`;

export const ContactImage = styled.img`
  width: 28rem;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    width: 90vw;
    margin-bottom: 1rem;
  }
`;

export const ContactusTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const ContactusForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: max-content;
  @media screen and (max-width: 1000px) {
    width: 80%;
  }
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 12pt;
`;

export const Input = styled.input`
  border-radius: 5px;
  border: none;
  height: 2.5rem;
  padding: 0 1rem;
  font-size: 13pt;
  margin-bottom: 1.5rem;
  background: #dfdfdf;
  &:focus {
    outline: 2px solid black;
  }
`;

export const Textarea = styled.textarea`
  border-radius: 5px;
  background: #dfdfdf;
  border: none;
  height: 14rem;
  padding: 1rem;
  font-size: 13pt;
  color: var(--black);
  &:focus {
    outline: 2px solid black;
  }
`;

export const ContactUsBtn = styled.button`
  border-radius: 5px;
  color: var(--white);
  background: var(--primary);
  padding: 0.8rem;
  border: none;
  margin-top: 1rem;
  font-weight: 600;
  cursor: pointer;
`;
