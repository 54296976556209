import React from "react";
import {
  VesseImage,
  VesselCaption,
  VesselImageContainer,
  VesselImageWrapper,
  VesselsContainer,
} from "./VesselsStyle";
import { VesselImagesData } from "../../Data";
import { CommonHeading } from "../Hero/HeroStyle";

const Vessels = () => {
  return (
    <VesselsContainer id="vessels">
      <CommonHeading>Vessels We Handle...</CommonHeading>
      <VesselImageContainer>
        {VesselImagesData.map((image, index) => (
          <VesselImageWrapper key={index}>
            <VesseImage src={image.path} alt={image.name} />
            <VesselCaption>{image.name}</VesselCaption>
          </VesselImageWrapper>
        ))}
      </VesselImageContainer>
    </VesselsContainer>
  );
};

export default Vessels;
