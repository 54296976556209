import React, { useRef } from "react";
import {
  ContactImage,
  ContactUsBtn,
  ContactusContainer,
  ContactusForm,
  ContactusTextWrap,
  ContactusTitle,
  Input,
  Label,
  Textarea,
} from "./ContactusStyles";
import PaperRocket from "../../Images/PaperRocket.png";
import emailjs from "@emailjs/browser";

const Contactus = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qpor1ie",
        "enterprises-template",
        form.current,
        "Gvb8inN7hVYQ4ugUK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    alert("Email sent successfully");
  };

  return (
    <ContactusContainer id="contactus">
      <ContactusTextWrap>
        <ContactusTitle>Contact Us...</ContactusTitle>
        <p>
          Fill out our contact form with your name, email address, and message.
          We'll get back to you as soon as possible.
        </p>
        <ContactImage src={PaperRocket} alt="paper rocket" />
      </ContactusTextWrap>
      <ContactusForm ref={form} onSubmit={sendEmail}>
        <Label>Full Name</Label>
        <Input
          type="text"
          placeholder="Your full name here.."
          name="name"
          required
        />
        <Label>Email Address</Label>
        <Input
          type="email"
          placeholder="Enter your email address"
          name="email"
          required
        />
        <Label>Meassage</Label>
        <Textarea
          type="text"
          name="message"
          placeholder="Type your message to send"
          required
        ></Textarea>
        <ContactUsBtn>Send Mail</ContactUsBtn>
      </ContactusForm>
    </ContactusContainer>
  );
};

export default Contactus;
