import React from "react";
import {
  AboutContainer,
  AboutImage,
  AboutInfo,
  AboutInfoWrapper,
} from "./AboutusStyles";
import Img from "../../Images/AboutImg.png";
import { CommonHeading } from "../Hero/HeroStyle";

const Aboutus = () => {
  return (
    <AboutContainer id="about">
      <CommonHeading>About Us</CommonHeading>
      <AboutInfoWrapper>
        <AboutInfo>
          Established in 1980, SITHIRA ENTERPRISES as Sub Agents has over 40
          years’ of trusted experience in the shipping industry providing
          Shipping Agency support in the Port of Trincomalee, in Sri Lanka.
          <br />
          <br />
          Our main purpose is to provide shipping lines with pre-arrival to
          post-departure logistics and port services which includes liaison with
          the various service suppliers, Transportation services and government
          authorities etc.
          <br />
          <br />
          SITHIRA ENTERPRISES will ensure that vessels visiting the port of
          Trincomalee operations are provided with all required services in a
          timely, efficient and above all in a professional manner. With our
          experience in handling over thousands of vessels, we have built
          lasting professional relationships with all reputed shipping agencies
          in Sri Lanka.
        </AboutInfo>
        <AboutImage src={Img} alt="About Image" />
      </AboutInfoWrapper>
    </AboutContainer>
  );
};

export default Aboutus;
