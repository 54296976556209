import React from "react";
import {
  FooterContainer,
  FooterWrap,
  FooterContent,
  FooterLinkItems,
  FooterLinkTitle,
} from "./FooterStyles";
import MemberOf from "../../Images/MemberOf.jpg";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinkItems>
          <FooterLinkTitle>Member of</FooterLinkTitle>
          <FooterContent>
            <img
              style={{ borderRadius: "10px" }}
              src={MemberOf}
              alt="memory of"
            />
          </FooterContent>
        </FooterLinkItems>

        <FooterLinkItems>
          <FooterLinkTitle>Address</FooterLinkTitle>
          <FooterContent>NO: 97, COURT ROAD,</FooterContent>
          <FooterContent>TRINCOMALEE.</FooterContent>
        </FooterLinkItems>

        <FooterLinkItems>
          <FooterLinkTitle>Email</FooterLinkTitle>
          <FooterContent>sjeya@sltnet.lk</FooterContent>
          <FooterContent>sjeya53@gmail.com</FooterContent>
          <FooterContent>previnthjey51@gmail.com</FooterContent>
          <FooterContent>nirojey.sithira01@gmail.com</FooterContent>
        </FooterLinkItems>

        <FooterLinkItems>
          <FooterLinkTitle>Contact Number</FooterLinkTitle>
          <FooterContent>T.P : +94 11 236 0892</FooterContent>
          <FooterContent>T.P : +94 26 222 2113</FooterContent>
          <FooterContent>MOB : +94 77 729 6004</FooterContent>
          <FooterContent>MOB : +94 77 772 6970</FooterContent>
          <FooterContent>MOB : +94 76 686 9319</FooterContent>
        </FooterLinkItems>

        <FooterLinkItems>
          <FooterLinkTitle>Fax</FooterLinkTitle>
          <FooterContent>NO : +94 26 222 1520</FooterContent>
        </FooterLinkItems>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
