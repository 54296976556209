import React from "react";
import Home from "./Pages/Home";
import "./App.css";

const App = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default App;
