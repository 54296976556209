import React from "react";
import { SEGalleryData } from "../../Data";
import styled from "styled-components";
import { CommonHeading } from "../Hero/HeroStyle";

const GalleryContainerWrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 720px) {
    padding-left: 0;
  }
`;

const GalleryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 1.5rem;

  @media screen and (max-width: 420px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const GallerImage = styled.img`
  width: 15rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 5px;
  margin: 10px;
  background: var(--primary);

  &:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  }
  @media screen and (max-width: 1000px) {
    width: 10rem;
    height: 7rem;
    margin-bottom: 10px;
  }
`;

const Gallery = () => {
  return (
    <GalleryContainerWrapper id="gallery">
      <CommonHeading>Our Gallery...</CommonHeading>
      <GalleryContainer>
        {SEGalleryData.map((index) => (
          <GallerImage src={index} key={index} />
        ))}
      </GalleryContainer>
    </GalleryContainerWrapper>
  );
};

export default Gallery;
