import React, { useState } from "react";
import {
  MoreService,
  MoreServiceWrapper,
  ServiceCard,
  ServiceCardDesc,
  ServiceCardHeader,
  ServiceCardIcon,
  ServiceCardTitle,
  ServiceDataContainer,
  ServicesContainer,
} from "./ServicesStyles";
import { serviceData } from "../../Data";
import ServiceModal from "../Modal/ServiceModal";
import { CommonHeading } from "../Hero/HeroStyle";

const Services = () => {
  const [openModal, setOpenModal] = useState(false);
  const [images, setImages] = useState();

  const handleClick = (event) => {
    serviceData.map((item) => {
      if (item.id === event.target.id) {
        setOpenModal(true);
        setImages(item.images);
      }
    });
  };

  return (
    <>
      <ServicesContainer id="services">
        <CommonHeading>
          Key Logistics Services <br />
          We Provide...
        </CommonHeading>
        <ServiceDataContainer>
          {serviceData.map((item) => (
            <ServiceCard key={item.id}>
              <ServiceCardHeader>
                <ServiceCardIcon src={item.icon} alt={item.alt} />
                <ServiceCardTitle>{item.title}</ServiceCardTitle>
              </ServiceCardHeader>
              <ServiceCardDesc>{item.description}</ServiceCardDesc>
              {item.isDetail && (
                <MoreServiceWrapper>
                  <MoreService
                    cursor={item.isDetail}
                    onClick={handleClick}
                    id={item.id}
                  >
                    Click for more
                  </MoreService>
                </MoreServiceWrapper>
              )}
            </ServiceCard>
          ))}
        </ServiceDataContainer>
      </ServicesContainer>
      {openModal && (
        <ServiceModal imageArray={images} closeModal={setOpenModal} />
      )}
    </>
  );
};

export default Services;
