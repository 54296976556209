import React, { useRef } from "react";
import {
  CloseIcon,
  Icon,
  ServiceModalBG,
  ServiceModalContainer,
  ServiceModalImage,
  ServiceModalImageWrapper,
} from "./ServiceModalStyles";

const ServiceModal = ({ closeModal, imageArray }) => {
  const ModalRef = useRef();

  const handleClick = (e) => {
    if (ModalRef.current === e.target) {
      closeModal(false);
    }
  };

  return (
    <>
      <ServiceModalBG ref={ModalRef} onClick={handleClick}>
        <ServiceModalContainer>
          <ServiceModalImageWrapper>
            {imageArray &&
              imageArray.map((item) => <ServiceModalImage src={item} />)}
          </ServiceModalImageWrapper>
          <Icon onClick={() => closeModal(false)}>
            <CloseIcon />
          </Icon>
        </ServiceModalContainer>
      </ServiceModalBG>
    </>
  );
};

export default ServiceModal;
