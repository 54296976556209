import styled from "styled-components";

export const AboutContainer = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

export const AboutInfoWrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const AboutInfo = styled.p`
  font-size: 12pt;
  text-align: justify;
  width: 45%;
  padding: 1rem;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;

export const AboutImage = styled.img`
  width: 35rem;
  object-fit: contain;
  padding: 1rem;
  margin-left: 10px;
  @media screen and (max-width: 1300px) {
    min-width: 24rem;
    margin: 0;
    margin-bottom: 10px;
  }
`;
