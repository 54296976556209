export const serviceData = [
  {
    id: "1",
    icon: "../Public_Images/store.png",
    alt: "Store icon",
    title: "Provisions & Store Supply",
    isDetail: true,
    description:
      "Liaison with the various service suppliers for scheduling and delivery of goods and services to the vessel.",
    images: [
      "../Public_Images/PSS1.jpg",
      "../Public_Images/PSS2.jpg",
      "../Public_Images/PSS3.jpg",
      "../Public_Images/PSS4.jpg",
    ],
  },
  {
    id: "2",
    icon: "../Public_Images/stevedrone.png",
    alt: "Steve Drone icon",
    title: "Stevedore Arrangement",
    isDetail: true,
    description:
      "Efficient Stevedore Arrangements: Let us handle your loading and unloading needs. Trust us for seamless cargo handling and vessel operations.",
    images: [
      "../Public_Images/SA1.jpg",
      "../Public_Images/SA2.jpg",
      "../Public_Images/SA3.jpg",
      "../Public_Images/SA4.jpg",
    ],
  },
  {
    id: "3",
    icon: "../Public_Images/crew.png",
    alt: "Crew icon",
    title: "Crew Change Cordination",
    isDetail: true,
    description:
      "Smooth Crew Change Coordination: Trust us for seamless crew changeovers. We handle everything from logistics to visa arrangements.",
    images: [
      "../Public_Images/CCC1.jpg",
      "../Public_Images/CCC2.jpg",
      "../Public_Images/CCC3.jpg",
      "../Public_Images/CCC4.jpg",
      "../Public_Images/CCC5.jpg",
    ],
  },
  {
    id: "4",
    icon: "../Public_Images/anchor.png",
    alt: "Anchor icon",
    title: "Berth / Anchorage Spot",
    isDetail: false,
    description:
      "Berth/Anchorage Spot booking for the vessel with the port authorities (Call announcement).",
  },
  {
    id: "5",
    icon: "../Public_Images/linking.png",
    alt: "Linking icon",
    title: "Liaison with Vessels",
    isDetail: false,
    description:
      "Liaison with the various authorities required in providing different services to the vessel.",
  },
];

export const SEGalleryData = [
  "../Public_Images/manship19.jpg",
  "../Public_Images/manship20.jpg",
  "../Public_Images/ship1.jpg",
  "../Public_Images/ship2.jpg",

  "../Public_Images/ship11.jpg",
  "../Public_Images/ship12.jpg",
  "../Public_Images/ship13.jpg",
  "../Public_Images/ship16.jpg",

  "../Public_Images/manship17.jpg",
  "../Public_Images/ship33.jpg",
  "../Public_Images/ship34.jpg",
  "../Public_Images/manship23.jpg",

  "../Public_Images/ship27.jpg",
  "../Public_Images/manship26.jpg",
  "../Public_Images/ship28.jpg",
  "../Public_Images/ship29.jpg",

  "../Public_Images/ship30.jpg",
  "../Public_Images/ship31.jpg",
  "../Public_Images/manship24.jpg",
  "../Public_Images/ship32.jpg",

  "../Public_Images/manship25.jpg",
  "../Public_Images/ship35.jpg",
  "../Public_Images/ship36.jpg",
  "../Public_Images/ship37.jpg",

  "../Public_Images/ship38.jpg",
  "../Public_Images/manship18.jpg",
  "../Public_Images/manship21.jpg",
  "../Public_Images/ship39.jpg",

  "../Public_Images/ship40.jpg",
  "../Public_Images/ship41.jpg",
  "../Public_Images/manship22.jpg",
];

export const VesselImagesData = [
  {
    name: "Dry Bulk Carrier Vessels",
    path: "../Public_Images/DryBulkShip.jpg",
  },
  { name: "Bunkering Vessels", path: "../Public_Images/BunkeringVessel.jpg" },
  { name: "Project Vessels", path: "../Public_Images/ProjectCargoVessel.jpg" },
  { name: "Naval Vessels", path: "../Public_Images/NavalVessels.jpg" },
  { name: "Lay Up Vessels", path: "../Public_Images/LayUpVessels.png" },
  { name: "Passenger Vessels", path: "../Public_Images/PassengerVessel.jpg" },
  { name: "Private Yachts", path: "../Public_Images/Yacht.jpg" },
];

export const HighlightData = [
  { title: "40 +", subtitle: "Years", bar: true },
  { title: "24 Hrs", subtitle: "Piloting", bar: true },
  { title: "Level 1", subtitle: "Security", bar: false },
];

export const CommitteeData = [
  {
    name: " S.Jeyarajah",
    position: "Managing Director",
    photo: "../Public_Images/man1.jpg",
    nmbr: "No - +94 77 772 6970",
  },
  {
    name: "Jeyarajah Nirooshan",
    position: "Director",
    photo: "../Public_Images/man2.jpg",
    nmbr: "No - +94 77 729 6004",
  },
  {
    name: "Previnth Jeyarajah",
    position: "General Manager",
    photo: "../Public_Images/man3.jpg",
    nmbr: "No - +94 76 686 9319",
  },
];
