import React from "react";
import styled from "styled-components";
import { CommonHeading } from "../Hero/HeroStyle";

const HighlightContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 30vh;
  max-height: max-content;
  width: 100%;
`;

const HighlighP = styled.p`
  width: 75%;
  text-align: justify;
  margin-top: 10px;
  @media screen and (max-width: 760px) {
    width: 80%;
  }
`;

const HighlightH3 = styled.h3`
  font-size: 24pt;
  margin-top: 20px;
`;

const BullentWrap = styled.ul`
  width: 75%;
`;

const Bullets = styled.li`
  margin-top: 10px;
`;

const Highlights = () => {
  return (
    <HighlightContainer>
      <CommonHeading>
        40+ Years Service as Sub Agents in Trincomalee
      </CommonHeading>
      <HighlightH3>Port of Operation</HighlightH3>
      <HighlighP>
        Trincomalee Harbour is a seaport in Trincomalee Bay or Koddiyar Bay, a
        large natural harbour situated in the northeastern coast of Sri Lanka.
        Considered the second best natural harbor in the world.
      </HighlighP>
      <BullentWrap>
        <Bullets>24 Hrs of Piloting</Bullets>
        <Bullets>Level 1 Security</Bullets>
      </BullentWrap>
    </HighlightContainer>
  );
};

export default Highlights;
