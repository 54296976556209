import React from "react";
import styled from "styled-components";

const RightsContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: var(--primary);
  padding: 20px;
  font-size: 10pt;
  text-align: center;
`;

const Rights = () => {
  return (
    <RightsContainer>
      <p>
        Copyright © Shitihra Enterprises {new Date().getFullYear()}. All rights
        reserved.
      </p>
    </RightsContainer>
  );
};

export default Rights;
