import styled from "styled-components";

export const VesselsContainer = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
`;

export const VesselImageContainer = styled.div`
  width: 85%;
  height: 40vh;
  margin-top: 2rem;
  overflow-x: scroll;
  display: flex;

  ::-webkit-scrollbar {
    display: flex;
    height: 6px;
  }
  /* Style the custom scroll bar */
  ::-webkit-scrollbar-track {
    background-color: var(--gray);
    border-radius: 10px;
    width: 2rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
    width: 1rem;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--black);
  }
`;

export const VesselImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  margin: 10px;
  color: var(--black);
`;

export const VesseImage = styled.img`
  width: 15rem;
  height: 11rem;
  object-fit: cover;
  border-radius: 5px;
`;

export const VesselCaption = styled.p`
  font-size: 14pt;
  margin-top: 5px;
  font-weight: 600;
`;
