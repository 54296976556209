import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

export const ServiceModalBG = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background: #00000073;
  font-size: 25pt;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ServiceModalContainer = styled.div`
  padding: 1rem;
  display: flex;
  max-width: 92%;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 25;
  background-color: var(--white);
  border-radius: 10px;
  position: relative;
  @media screen and (max-width: 720px) {
    max-width: 88%;
  }
`;

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

export const Icon = styled.div`
  position: absolute;
  top: -3rem;
  right: 0rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const ServiceModalImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ServiceModalImage = styled.img`
  width: 14rem;
  height: 11rem;
  object-fit: cover;
  margin: 10px;
  @media screen and (max-width: 720px) {
    height: 8rem;
    width: 10rem;
  }
  @media screen and (max-width: 400px) {
    height: 6rem;
    width: 8rem;
  }
`;
