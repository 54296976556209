import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: var(--primary);
  height: max-content;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 75vw;
  padding: 1rem;
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px;
  text-align: left;
  min-width: 10rem;
  box-sizing: border-box;
  color: #fff;
  gap: 0.5rem;
`;

export const FooterLinkTitle = styled.h2`
  font-size: 14pt;
  margin-bottom: 16px;
`;

export const FooterContent = styled.div`
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
`;
