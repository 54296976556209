import styled from "styled-components";

export const ServicesContainer = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  background-image: url("../Public_Images/ServiceCoverImg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: var(--white);
`;

export const ServicesHeading = styled.h2`
  color: var(--black);
  padding: 5.5rem 1.5rem 0 0;
  font-size: 2.5rem;
  font-weight: 800;
  width: 74%;
`;

export const ServiceDataContainer = styled.div`
  height: auto;
  padding: 2rem;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const ServiceCard = styled.article`
  cursor: ${({ cursor }) => (cursor ? "pointer" : "mouse")};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 19rem;
  min-height: 14rem;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  color: var(--black);
  &:hover {
    color: var(--white);
    background: var(--primary);
  }
  @media screen and (max-width: 450px) {
    min-width: 19rem;
  }
`;

export const ServiceCardHeader = styled.header`
  display: flex;
`;

export const ServiceCardIcon = styled.img`
  object-fit: cover;
  background: var(--primary);
  height: 4rem;
  width: 4rem;
  border-radius: 5px;
  padding: 0.5rem;
`;

export const ServiceCardTitle = styled.h3`
  font-size: 16pt;
  margin-left: 20px;
  width: 70%;
`;

export const ServiceCardDesc = styled.p`
  font-size: 10pt;
  margin: 10px 0;
  width: 95%;
`;

export const MoreServiceWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const MoreService = styled.div`
  cursor: pointer;
  font-size: 10pt;
  color: #1a52ca;
  z-index: 1;
  height: 2.5rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
